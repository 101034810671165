import axios from "axios";
import config from '../config.json';

export const adminLogin = async (obj) => {
    return axios.post(`${config.USER_API}/authen/login`, obj, {
        headers: {
            'Content-Type': 'application/json'
        }
    })
}
