import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton, InputAdornment } from '@mui/material';
import Icon from 'react-icons-kit';
// import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { eye } from 'react-icons-kit/feather/eye'
import { eyeOff } from 'react-icons-kit/feather/eyeOff'
import * as userHttpServices from './../../services/user-services'
import BG from './../../images/menu/rice-560051.jpg'
import { BiArrowBack } from "react-icons/bi";

const UserRegister = () => {
    // const navigate = useNavigate();

    const [inputText, setInputText] = useState({
        username: "",
        fname: "",
        lname: "",
        email: "",
        phone: "",
        password: "",
        confirm_password: ""
    });

    const [warnUsername, setWarnUsername] = useState(false);
    const [warnFname, setWarnFname] = useState(false);
    const [warnLname, setWarnLname] = useState(false);
    const [warnEmail, setWarnEmail] = useState(false);
    const [warnPhone, setWarnPhone] = useState(false);
    const [warnPassword, setWarnPassword] = useState(false);
    const [warnConfirmPassword, setWarnConfirmPassword] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);

    const [icon, setIcon] = useState(eyeOff);
    const [icon2, setIcon2] = useState(eyeOff);

    const handleUsernameChange = (event) => {
        const { name, value } = event.target;
        setInputText((prevState) => ({
            ...prevState,
            [name]: value.replace(/[^a-zA-Z0-9]/g, '').toLowerCase()
        }));
    }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setInputText((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleToggle = () => {
        setIcon(showPassword ? eyeOff : eye);
        setShowPassword((prevState) => !prevState);
    }

    const handleToggle2 = () => {
        setIcon2(showPassword2 ? eyeOff : eye);
        setShowPassword2((prevState) => !prevState);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        let isValid = true

        setWarnUsername(false);
        setWarnFname(false);
        setWarnLname(false);
        setWarnPassword(false);
        setWarnEmail(false);
        setWarnPhone(false);
        setWarnConfirmPassword(false);

        if (inputText.username === "") {
            setWarnUsername(true);
            isValid = false
        }
        if (inputText.fname === "") {
            setWarnFname(true);
            isValid = false
        }
        if (inputText.lname === "") {
            setWarnLname(true);
            isValid = false
        }
        if (inputText.phone === "") {
            setWarnPhone(true);
            isValid = false
        }
        if (inputText.password === "") {
            setWarnPassword(true);
            isValid = false
        }
        if (inputText.confirm_password === "") {
            setWarnConfirmPassword(true);
            isValid = false
        }

        const passwordRegex = /^(?=.*?[a-z])(?=.*?[0-9]).{8,}$/;
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const thaiPhoneRegex = /^0[689]\d{8}$/;

        if (!passwordRegex.test(inputText.password)) {
            // alert('รหัสผ่านอย่างน้อย 8 ตัวอักษร a-z อย่างน้อย 1 ตัวและ 0-9 อย่างน้อย 1 ตัว')
            setWarnPassword(true);
            isValid = false
        }
        else if (inputText.password !== inputText.confirm_password || inputText.password === "") {
            // alert('กรุณากรอกรหัสผ่าน และ ยืนยันรหัสผ่านให้ตรงกัน')
            setWarnConfirmPassword(true);
            isValid = false
        }
        
        // if (inputText.email !== '' && !emailRegex.test(inputText.email)) {
        //     // Check if the email is valid
        //     alert('กรุณากรอกอีเมลให้ถูกต้อง')
        //     setWarnEmail(true);
        // } else if (inputText.phone !== '' && !thaiPhoneRegex.test(inputText.phone)) {
        //     // Check if the phone is valid
        //     alert('กรุณาเบอร์โทรศัพท์ให้ถูกต้อง')
        //     setWarnPhone(true);
        // }

        if (!thaiPhoneRegex.test(inputText.phone)) {
            // Check if the phone is valid
            // alert('กรุณาเบอร์โทรศัพท์ให้ถูกต้อง เช่น 0812345678')
            setWarnPhone(true);
            isValid = false
        }
        
        if (!isValid) {
            alert('กรุณากรอกข้อมูลครบถ้วน');
        } else {
            const fetchCreate = async () => {
                try {
                    let obj = {
                        "username": inputText.username,
                        "first_name": inputText.fname,
                        "last_name": inputText.lname,
                        // "email": inputText.email,
                        "phone": inputText.phone,
                        "password": inputText.password,
                        // "confirm_password": inputText.confirm_password,
                        "org_id": Number(73),
                        "app_id": Number(9)
                    }

                    const response = await userHttpServices.addUser(obj);
                    console.log('response', response)

                    alert('ลงทะเบียนสำเร็จ')
                    window.location.href = `/user/login`
                } catch (error) {
                    console.log(error)
                    if (error.response) {
                        if (error?.response?.status === 400) {
                            if (error?.response?.data?.response?.message === "username is already") {
                                alert("ชื่อผู้ใช้นี้งานถูกใช้งานแล้ว")
                            } else {
                                alert(error?.response?.data?.response?.message)
                            }
                        } else if (error.response.status === 401) {
                            alert('ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง')
                        }
                    } else {
                        console.error(error);
                    }
                }

            };

            fetchCreate();
        }
    };

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundImage: `url(${BG})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'bottom'
            }}>
                
                <Link href="/user/login">
                    <BiArrowBack size={30} color={"gray"} style={{ position: 'absolute', top: 20, left: 20 }}/>
                </Link>
                <Box
                    sx={{
                        margin: 3,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        padding: 3,
                        borderRadius: '10px',
                        boxShadow: '0 0.25rem 1rem 0  rgba(47, 91, 234, 0.125)',
                        backgroundColor: 'white'
                    }}
                >
                    <Typography sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                        ลงทะเบียน
                    </Typography>
                    <Typography sx={{ fontSize: '14px', mt: 1 }}>
                        ป้อนข้อมูลประจำตัวเพื่อลงทะเบียนบัญชีของคุณ
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 4 }}>
                        <TextField
                            type="text"
                            placeholder="กรอกชื่อผู้ใช้งาน"
                            value={inputText.username}
                            onChange={handleUsernameChange}
                            name="username"
                            autoComplete='off'
                            fullWidth
                            error={warnUsername}
                            helperText={warnUsername && "กรุณากรอกชื่อผู้ใช้งานให้ถูกต้อง"}
                        />
                        <TextField
                            sx={{ mt: 2 }}
                            type="text"
                            placeholder="กรอกชื่อ"
                            value={inputText.fname}
                            onChange={handleInputChange}
                            name="fname"
                            autoComplete='off'
                            fullWidth
                            error={warnFname}
                            helperText={warnFname && "กรุณากรอกชื่อให้ถูกต้อง"}
                        />
                        <TextField
                            sx={{ mt: 2 }}
                            type="text"
                            placeholder="กรอกนามสกุล"
                            value={inputText.lname}
                            onChange={handleInputChange}
                            name="lname"
                            autoComplete='off'
                            fullWidth
                            error={warnLname}
                            helperText={warnLname && "กรุณากรอกนามสกุลให้ถูกต้อง"}
                        />
                        {/* <TextField
                            sx={{ mt: 2 }}
                            type="text"
                            placeholder="กรอกอีเมล"
                            value={inputText.email}
                            onChange={handleInputChange}
                            name="email"
                            autoComplete='off'
                            fullWidth
                            error={warnEmail}
                            helperText={warnEmail && "กรุณากรอกอีเมลให้ถูกต้อง"}
                        /> */}
                        <TextField
                            sx={{ mt: 2 }}
                            type="text"
                            placeholder="กรอกเบอร์โทรศัพท์"
                            value={inputText.phone}
                            onChange={handleInputChange}
                            name="phone"
                            autoComplete='off'
                            fullWidth
                            error={warnPhone}
                            helperText={warnPhone && "กรุณาเบอร์โทรศัพท์ให้ถูกต้อง เช่น 0812345678"}
                        />
                        <TextField
                            sx={{ mt: 2 }}
                            type={showPassword ? "text" : "password"}
                            placeholder="กรอกรหัสผ่าน"
                            value={inputText.password}
                            onChange={handleInputChange}
                            name="password"
                            autoComplete='off'
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggle} edge="end">
                                            <Icon icon={icon} size={18} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={warnPassword}
                            helperText={warnPassword && "อย่างน้อย 8 ตัวอักษร โดยมี a-z อย่างน้อย 1 ตัวและ 0-9 อย่างน้อย 1 ตัว"}
                        />
                        <TextField
                            sx={{ mt: 2 }}
                            className={`${warnConfirmPassword ? "warning" : ""}`}
                            type={showPassword2 ? "text" : "password"}
                            placeholder="กรุณากรอกรหัสผ่าน และ ยืนยันรหัสผ่านให้ตรงกัน"
                            value={inputText.confirm_password}
                            onChange={handleInputChange}
                            name="confirm_password"
                            autoComplete='off'
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggle2} edge="end">
                                            <Icon icon={icon2} size={18} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            error={warnConfirmPassword}
                            helperText={warnConfirmPassword && "กรุณากรอกรหัสผ่านยืนยันให้ถูกต้อง"}
                        />
                        <Button
                            style={{ backgroundColor: 'green' }}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            size="large"
                        >
                            ลงทะเบียน
                        </Button>
                        <Grid sx={{ display: 'flex', justifyContent: 'center' }}>
                            <p style={{ fontSize: '12px' }}>
                                มีบัญชีผู้ใช้แล้ว <Link href="/user/login">เข้าสู่ระบบ </Link>
                            </p>
                        </Grid>
                    </Box>
                </Box>
            </div>
        </>
    );
}

export default UserRegister