import { useEffect, useState } from "react"
import Carousel from 'react-material-ui-carousel'
import { Paper } from '@mui/material'
import { pafService } from '../../services/allrice/paf_services'

export default function Banner() {
  const [news, setNews] = useState([])

  useEffect(() => {
    const fetch = async () => {
      const data = await pafService('/news/banner')
      setNews(data?.data?.data ?? [])
    }
    fetch()
    // setTimeout(fetch, 4000)
  }, [])

  return (
    <div style={{ width: "100%", paddingTop: 28, paddingBottom: 0, boxSizing: 'border-box' }}>
      <Carousel
        autoPlay={true}

        // indicatorIconButtonProps={{
        //   style: {
        //     color: 'gray'
        //   }
        // }}
        // activeIndicatorIconButtonProps={{
        //   style: {
        //     backgroundColor: 'white'
        //   }
        // }}
        indicatorContainerProps={{
          style: {
            marginTop: "-30px",
            position: "relative",
            zIndex: 1,
            transform: 'scale(0.7)'
          }

        }}
      >
        {
          news.length === 0 ? (
            <Paper key={-1} style={{ width: '100%', height: '70vw', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#888888' }}>
              <span style={{ fontSize: '14', padding: 8 }}>กำลังโหลด</span>
            </Paper>
          ) :
            news.map((item, i) => {
              return (
                item.image && item.image !== '' ? (
                  <a key={i} href={`#news-${item.id}`} style={{ textDecoration: 'none' }}>
                    <Paper key={i} style={{ width: '100%', height: '70vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'end', position: 'relative' }}>
                      <img style={{ width: '100%', height: '100%', objectFit: 'cover', paddingBottom: 40 }} src={item.image} alt={item.title} />
                      <span style={{ background: 'black', fontSize: '4vw', width: '100%', padding: 8, paddingBottom: 30, boxSizing: 'border-box', color: 'white', position: 'absolute', bottom: 0 }}>{item.title}</span>
                    </Paper>
                  </a>
                ) : (
                  <a key={i} href={`#news-${item.id}`} style={{ textDecoration: 'none' }}>
                    <Paper key={i} style={{ width: '100%', height: '70vw', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#888888' }}>
                      <span style={{ fontSize: '14', padding: 8, boxSizing: 'border-box', paddingBottom: 30, position: 'absolute', bottom: 0 }}>{item.title}</span>
                    </Paper>
                  </a>
                )
              )
            })
        }
      </Carousel>
    </div>
  )
}