import axios from 'axios';
import config from '../../config.json';

export const pafService = async (uri, obj) => {
  return axios.get(
    config.PAF_API + uri, obj,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
}

export const getLocation = async (latitude, longitude) => {
  const apiUrl = `https://nominatim.openstreetmap.org/reverse?lat=${latitude}&lon=${longitude}&format=json`;
  return axios.get(apiUrl)
}